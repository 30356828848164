<template>
  <v-card>
    <v-card-title>Carregando...</v-card-title>

    <div class="d-flex justify-center align-center pa-10">
      <v-progress-circular
        indeterminate
        color="primary"
        size="50"
      ></v-progress-circular>
    </div>
  </v-card>
</template>

<script>
export default {};
</script>

<style>
</style>