<template>
  <div class="schedules-form">
    <ModalProgressIndicator v-if="loading && dialog" />

    <BaseForm
      v-else
      :title="internalTitle"
      :mod="mod"
      :flat="flat"
      :divider="divider"
      :dialog="dialog"
      :loading="loading"
      @save="save"
      @close-dialog="$emit('close-dialog')"
    >
      <template #header-actions>
        <v-btn icon title="Imprimir recibo" @click="printPayment(form)"
          ><v-icon>mdi-printer</v-icon></v-btn
        >
      </template>

      <template #content>
        <v-container>
          <v-row
            ><v-col>
              <v-select
                class="required"
                label="Status"
                :items="[
                  { text: 'Pago', value: 'paid' },
                  { text: 'Pendente', value: 'pending' },
                ]"
                v-model="formPaymentStatus"
              ></v-select> </v-col
          ></v-row>

          <v-row>
            <v-col>
              <PriceField class="required" label="Valor" v-model="formValue" />
            </v-col>

            <v-col>
              <v-text-field
                class="required"
                label="Data de vencimento"
                v-model="formDueDate"
                type="date"
              ></v-text-field>
            </v-col>

            <v-col>
              <v-text-field
                label="Data de pagamento"
                v-model="formPayDay"
                type="date"
              >
              </v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-text-field
                class="required"
                label="Referente"
                v-model="formRegarding"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-text-field
                class="required"
                label="Nome do responsável"
                v-model="formNameOfResponsible"
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                label="CPF ou CNPJ do responsável"
                v-model="formTaxDocument"
                v-mask="['###.###.###-##', '##.###.###/####-##']"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-select
                class="required"
                label="Forma de pagamento"
                :items="paymentMethods"
                item-text="name"
                item-value="id"
                v-model="formPaymentMethod"
              ></v-select>
            </v-col>
            <v-col>
              <v-select
                class="required"
                label="Parcelamento"
                :items="[
                  { text: 'Á vista', value: 'in-cash' },
                  { text: 'Parcelado', value: 'installments' },
                ]"
                v-model="formPaymentType"
              ></v-select>
            </v-col>
            <v-col v-if="formPaymentType === 'installments'">
              <v-text-field
                class="required"
                label="Qtd. Parcelas"
                type="number"
                v-model="formInstallments"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-checkbox
                label="Imprimir recibo ?"
                v-model="printPReceipt"
              ></v-checkbox>
            </v-col>
          </v-row>
        </v-container>
      </template>

      <template #actions> </template>
    </BaseForm>

    <v-dialog v-model="successDialog" persistent max-width="450">
      <v-card>
        <v-card-text>
          <div class="text-center pa-4">
            <v-icon large color="success">mdi-check</v-icon>
          </div>

          <div class="text-center pa-3">
            <h3>Dados atualizados com sucesso</h3>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="closeSuccessDialog">Fechar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import formMixin from "@/mixins/formMixin";
import BaseForm from "@/components/template/BaseForm";
import errorHandler from "@/helpers/error_handler";
import { mask } from "vue-the-mask";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import ModalProgressIndicator from "@/components/template/ModalProgressIndicator.vue";
import PriceField from "@/components/template/FormElements/PriceField";

import { printPaymentReceipt } from "../../../helpers/schedules";

export default {
  directives: { mask },
  components: {
    BaseForm,
    ModalProgressIndicator,
    PriceField,
  },
  mixins: [formMixin],
  props: ["externalLoading"],
  data: () => ({
    printPReceipt: false,
    internalLoading: false,
    loadingPaymentMethods: false,
    loadingSchedulePaymentData: false,
    successDialog: false,
    actionAfterOperation: null,
    schedulePaymentInsertedOrUpdated: null,
    internalMod: null,
  }),
  watch: {
    computedMode(newMode, oldMode) {
      if (!(oldMode === "view" && newMode === "update")) this.clearForm();
    },
    formProcedures() {
      console.log("Detectou mudança em procedimentos");
      this.updateEndTime();
    },
    formStartTime() {
      console.log("Detectou mudança no horário de ínicio");
      this.updateEndTime();
    },
  },
  computed: {
    ...mapState("schedulePayment", ["form", "paymentMethods", "mode"]),
    ...mapGetters("schedulePayment", ["getFormData"]),
    computedMode() {
      return this.mode || this.mod;
    },
    internalTitle() {
      return this.computedMode === "insert"
        ? "Incluir pagamento"
        : `Detalhes de pagamento do agendamento N. ${this.paymentScheduleId}`;
    },
    loading() {
      return this.externalLoading || this.internalLoading;
    },
    paymentScheduleId() {
      return this.form.id || this.databaseId || this.$route.params.id;
    },
    formPaymentStatus: {
      get: function () {
        return this.getFormData("status");
      },
      set: function (value) {
        this.setFormData({ key: "status", value: value });
      },
    },
    formValue: {
      get: function () {
        return this.getFormData("value");
      },
      set: function (value) {
        this.setFormData({ key: "value", value: value });
      },
    },
    formDueDate: {
      get: function () {
        return this.getFormData("dueDate");
      },
      set: function (value) {
        this.setFormData({ key: "dueDate", value: value });
      },
    },
    formPayDay: {
      get: function () {
        return this.getFormData("payDay");
      },
      set: function (value) {
        this.setFormData({ key: "payDay", value: value });
      },
    },
    formRegarding: {
      get: function () {
        return this.getFormData("regarding");
      },
      set: function (value) {
        this.setFormData({ key: "regarding", value: value });
      },
    },
    formNameOfResponsible: {
      get: function () {
        return this.getFormData("nameOfResponsible");
      },
      set: function (value) {
        this.setFormData({ key: "nameOfResponsible", value: value });
      },
    },
    formTaxDocument: {
      get: function () {
        return this.getFormData("taxDocumentOfResponsible");
      },
      set: function (value) {
        this.setFormData({ key: "taxDocumentOfResponsible", value: value });
      },
    },
    formPaymentMethod: {
      get: function () {
        return this.getFormData("PaymentMethodId");
      },
      set: function (value) {
        this.setFormData({ key: "PaymentMethodId", value: value });
      },
    },
    formInstallments: {
      get: function () {
        return this.getFormData("numberOfInstallments");
      },
      set: function (value) {
        this.setFormData({ key: "numberOfInstallments", value: value });
      },
    },
    formPaymentType: {
      get: function () {
        return this.getFormData("paymentType");
      },
      set: function (value) {
        this.setFormData({ key: "paymentType", value: value });
      },
    },
    formStatus: {
      get: function () {
        return this.getFormData("status");
      },
      set: function (value) {
        this.setFormData({ key: "status", value: value });
      },
    },
  },
  methods: {
    ...mapActions("schedulePayment", [
      "vLoadSchedulePaymentData",
      "vInitForm",
      "vLoadPaymentMethods",
      "vInsert",
      "vUpdate",
    ]),
    ...mapMutations("schedulePayment", [
      "setFormData",
      "setDuration",
      "initForm",
      "clearForm",
      "setMode",
    ]),
    printPayment() {
      printPaymentReceipt(this.form);
    },
    validate() {
      const errors = [];

      if (!this.formDueDate)
        errors.push("O campo DATA DE VENCIMENTO é obrigatório.");

      if (!this.formValue) errors.push("O campo VALOR é obrigatório.");

      if (!this.formRegarding)
        errors.push("O campo REFERENTE A é obrigatório.");

      if (!this.formNameOfResponsible)
        errors.push("O campo NOME DO RESPONSÁVEL é obrigatório.");

      // if (!this.formTaxDocument)
      //   errors.push("O campo CPF/CNPJ DO RESPONSÁVEL é obrigatório.");

      if (!this.formPaymentMethod)
        errors.push("O campo FORMA DE PAGAMENTO é obrigatório.");

      if (!this.formPaymentType)
        errors.push("O campo PARCELAMENTO é obrigatório.");

      if (this.formPaymentType === "installments" && !this.formInstallments)
        errors.push("O campo QTD. PARCELAS é obrigatório.");

      return errors;
    },
    save(actionAfterOperation) {
      const errors = this.validate();

      this.actionAfterOperation = actionAfterOperation;

      if (errors.length) {
        this.$root.$errorDialog(errors);
      } else {
        this[this.computedMode]();
      }
    },
    async insert() {
      try {
        this.internalLoading = true;

        this.schedulePaymentInsertedOrUpdated = await this.vInsert(
          this.form.SchedulingId
        );

        this.successDialog = true;
      } catch (error) {
        this.handleError(error);
      } finally {
        this.internalLoading = false;
      }
    },
    async update() {
      try {
        this.internalLoading = true;

        this.schedulePaymentInsertedOrUpdated = await this.vUpdate({
          scheduleId: this.form.SchedulingId,
          paymentId: this.paymentScheduleId,
        });

        this.successDialog = true;
      } catch (error) {
        this.handleError(error);
      } finally {
        this.internalLoading = false;
      }
    },

    async loadInitialData() {
      try {
        this.internalLoading = true;

        await this.vLoadSchedulePaymentData(this.paymentScheduleId);
      } catch (error) {
        this.handleError(error);
      } finally {
        this.internalLoading = false;
      }
    },
    async loadPaymentMethods() {
      try {
        this.loadingPaymentMethods = true;

        await this.vLoadPaymentMethods();
      } catch (error) {
        this.handleError(error);
      } finally {
        this.loadingPaymentMethods = false;
      }
    },

    closeSuccessDialog() {
      this.successDialog = false;

      if (this.printPReceipt) this.printPayment();

      if (this.computedMode === "insert") {
        this.clearForm();
        this.afterInsertion(this.actionAfterOperation, {
          ...this.schedulePaymentInsertedOrUpdated,
        });
      } else if (this.computedMode === "update") {
        this.afterUpdate(this.actionAfterOperation);
      } else {
        this.$emit("close-dialog");
      }
    },
    handleError(error) {
      console.log(error);

      const errorHandled = errorHandler.treatError(error);

      this.$root.$errorDialog(errorHandled);
    },
  },
  created() {
    this.loadPaymentMethods();
    // if (this.computedMode === "update") this.loadInitialData();
  },
};
</script>

<style scoped>
.schedules-form >>> .v-chip--disabled {
  opacity: 1;
}

.schedules-form >>> .theme--light.v-select .v-select__selection--disabled {
  color: rgba(0, 0, 0, 0.87);
}
</style>