<template>
  <v-container>
    <v-sheet
      elevation="1"
      class="pa-4 mb-5"
      color="light-blue lighten-5"
      shaped
    >
      <v-row>
        <v-col>
          <InfoTile label="Data" :text="scheduleDate" />
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <InfoTile label="Status">
            <template #label>
              <div class="d-flex align-center justify-start">
                Status
                <v-icon class="ml-1" small :color="scheduleStatusColor"
                  >mdi-checkbox-blank-circle</v-icon
                >
              </div>
            </template>

            <template #text>
              <v-select
                outlined
                :items="statuses"
                v-model="scheduleStatus"
                @change="updateStatus"
                :loading="updatingStatus"
                :disabled="
                  schedule.status === 'canceled' ||
                  !checkPermission('update-schedules-1')
                "
                hide-details
              >
              </v-select>
            </template>
          </InfoTile>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <InfoTile label="Local" :text="schedule.CompanyBranch.fantasyName" />
        </v-col>
      </v-row>

      <v-row class="mb-3" v-if="schedule.Agreement">
        <v-col>
          <InfoTile label="Convênio" :text="schedule.Agreement.name" />
        </v-col>
      </v-row>
    </v-sheet>

    <div class="my-4">
      <div class="subtitle-2">DADOS DO PACIENTE</div>
      <v-divider></v-divider>
    </div>

    <v-row class="mt-3">
      <v-col class="d-flex justify-start align-center">
        <InfoTile label="Paciente" :text="schedule.patient.fullName" />
        <v-btn
          class="mt-4 ml-2"
          title="Editar dados do paciente"
          icon
          @click.stop="personFormDialog = true"
        >
          <v-icon>mdi-account-edit</v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <InfoTile label="DATA DE NASCIMENTO" :text="patientDateOfBirth" />
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <InfoTile
          label="CPF"
          :text="schedule.patient.Patient.Person.taxDocument"
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <InfoTile
          label="Celular"
          :text="schedule.patient.Patient.Person.cellphone"
        />
      </v-col>
    </v-row>

    <v-row v-if="schedule.patient.Patient.Person.address">
      <v-col class="text-left">
        <InfoTile label="Endereço" :text="patientAddress" />
      </v-col>
    </v-row>

    <v-row v-if="schedule.patient.Patient.Person.district">
      <v-col md="9" class="text-left">
        <InfoTile
          label="Bairro"
          :text="schedule.patient.Patient.Person.district"
        />
      </v-col>
      <v-col md="3" class="text-right">
        <InfoTile label="Cep" :text="schedule.patient.Patient.Person.zipCode" />
      </v-col>
    </v-row>

    <div v-if="schedule.PaymentMethods && schedule.PaymentMethods.length">
      <div class="my-4">
        <div class="subtitle-2">FORMA DE PAGAMENTO</div>
        <v-divider></v-divider>
      </div>

      <v-row
        class="mb-3 mt-3"
        v-bind:key="`paymentMethod-${paymentMethod.id}`"
        v-for="paymentMethod in schedule.PaymentMethods"
      >
        <v-col class="text-left d-flex align-center">
          <v-icon small class="mr-2">
            {{
              paymentMethod.SchedulingPaymentMethod.status === "pending"
                ? "mdi-clock"
                : "mdi-check"
            }}</v-icon
          >
          {{ paymentMethod.name }} -
          {{ formatMoney(paymentMethod.SchedulingPaymentMethod.value) }}
        </v-col>
        <v-col class="text-right d-flex align-center">
          <v-btn
            icon
            small
            @click="
              $emit('edit-payment', paymentMethod.SchedulingPaymentMethod.id)
            "
            ><v-icon>mdi-pencil</v-icon></v-btn
          >
          <v-btn
            icon
            small
            @click="deletePayment(paymentMethod.SchedulingPaymentMethod.id)"
            ><v-icon>mdi-delete</v-icon></v-btn
          >
        </v-col>
      </v-row>
    </div>

    <div class="my-4">
      <div class="subtitle-2">PROCEDIMENTOS</div>
      <v-divider></v-divider>
    </div>

    <v-row
      justify="space-between"
      v-bind:key="`procedure-${procedure.id}`"
      v-for="procedure in schedule.procedures"
    >
      <v-col class="text-left">{{ procedure.name }} </v-col>
      <v-col class="text-right">{{
        formatMoney(procedure.SchedulingProcedure.price)
      }}</v-col>
    </v-row>

    <v-divider class="my-3"></v-divider>

    <v-row>
      <v-col class="d-flex justify-end">
        <div class="my-4 text-right">
          <div class="subtitle-1">
            + Subtotal: {{ formatMoney(schedule.subtotal) }}
          </div>
          <div class="subtitle-1">
            - Desconto por convênio: {{ formatMoney(discountAgreement) }}
          </div>
          <div class="subtitle-1">- Desconto: {{ formatMoney(discount) }}</div>
          <div class="text-h6">+= Total: {{ formatMoney(schedule.total) }}</div>
        </div>
      </v-col>
    </v-row>

    <v-dialog v-model="personFormDialog" max-width="800">
      <PersonForm
        :databaseId="schedule.patient.Patient.Person.id"
        :title="`Editar dados do paciente`"
        :mod="`update`"
        :dialog="true"
        @close-dialog="personFormDialog = false"
        @update-completed="afterUpdatePatient"
      />
    </v-dialog>

    <v-dialog v-model="successDialog" persistent max-width="450">
      <v-card>
        <v-card-text>
          <div class="text-center pa-4">
            <v-icon large color="success">mdi-check</v-icon>
          </div>

          <div class="text-center pa-3">
            <h3>Dados atualizados com sucesso</h3>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="closeSuccessDialog">Fechar</v-btn></v-card-actions
        >
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import moment from "moment";
import InfoTile from "@/components/template/InfoTile.vue";
import PersonForm from "@/views/People/PersonForm.vue";
import errorHandler from "@/helpers/error_handler";
import { mapActions, mapState } from "vuex";

export default {
  props: ["schedule"],
  components: { InfoTile, PersonForm },
  data: () => ({
    scheduleStatus: null,
    updatingStatus: false,
    personFormDialog: false,
    successDialog: false,
    headers: [
      {
        text: "Nome",
        value: "name",
      },
      {
        text: "Valor",
        value: "SchedulingPaymentMethod.value",
      },
      {
        text: "Ações",
        value: "actions",
      },
    ],
    statusData: {
      pending: {
        name: "Pendente",
        color: "orange",
      },
      confirmed: {
        name: "Confirmado",
        color: "blue",
      },
      "waiting-for-service": {
        name: "Aguardando pelo serviço",
        color: "orange",
      },
      "patient-did-not-attend": {
        name: "Paciente não atendido",
        color: "red",
      },
      completed: {
        name: "Paciente atendido",
        color: "green",
      },
      canceled: {
        name: "Cancelado",
        color: "grey",
      },
    },
  }),
  computed: {
    ...mapState("user", ["userData"]),
    patientDateOfBirth() {
      const dateOfBirth = this.schedule.patient.Patient.Person.dateOfBirth;
      const formatedDateOfBirth =
        this.$moment(dateOfBirth).format("DD/MM/YYYY");
      const age = Math.floor(
        this.$moment().diff(dateOfBirth, "years", true)
      );
      return `${formatedDateOfBirth} (${age} anos)`;
    },
    statuses() {
      let statuses = [];
      for (const property in this.statusData) {
        statuses.push({
          text: this.statusData[property].name,
          value: property,
        });
      }

      return statuses;
    },
    discountAgreement() {
      let discountValue = 0.0;
      let discountAgreement = this.schedule.Agreement
        ? parseFloat(this.schedule.Agreement.discount)
        : 0.0;

      discountValue = (this.schedule.subtotal * discountAgreement) / 100;

      return discountValue;
    },
    discount() {
      let discountValue = 0.0;

      if (this.schedule.discountInPercentageFormat) {
        discountValue =
          (this.schedule.subtotal * parseFloat(this.schedule.discount)) / 100;
      } else {
        discountValue = parseFloat(this.schedule.discount);
      }

      return discountValue;
    },
    scheduleStatusColor() {
      return this.statusData[this.scheduleStatus].color;
    },
    scheduleCreationDate() {
      return `${moment
        .utc(this.schedule.createdAt)
        .format("DD/MM/YYYY [ás] HH:mm")}`;
    },
    scheduleDate() {
      return `${moment.utc(this.schedule.startDate).format("DD/MM/YYYY")} das ${
        this.schedule.startTime
      } ás ${this.schedule.endTime}`;
    },

    patientAddress() {
      return this.schedule.patient.Patient.Person
        ? `${this.schedule.patient.Patient.Person.address}, 
              ${this.schedule.patient.Patient.Person.number}`
        : "";
    },
  },
  methods: {
    ...mapActions("schedule", ["vUpdateStatus"]),
    checkPermission(permissionKey) {
      return (
        this.userData.accessProfile.Permissions.findIndex(
          (p) => p.key === permissionKey
        ) !== -1
      );
    },
    async deletePayment(paymentId) {
      const confirm = await this.$root.$confirm(
        "Atenção",
        "Deseja realmente DELETAR O PAGAMENTO do agendamento?"
      );

      if (confirm) this.$emit("delete-payment", paymentId);
    },
    formatMoney(money) {
      return parseFloat(money).toLocaleString("pt-br", {
        style: "currency",
        currency: "BRL",
      });
    },

    procedurePrice(price) {
      return parseFloat(price).toFixed(2).replace(".", ",");
    },
    async updateStatus(newStatus) {
      try {
        const msg =
          newStatus === "canceled"
            ? "EVITE CANCELAMENTOS DESNECESSÁRIOS. Somente cancele se o paciente não compareceu ou não irá comparecer, pois esta ação deletará parmanentemente este agendamento. Tem certeza?"
            : "Deseja realmente alterar o STATUS do agendamento?";
        const confirm = await this.$root.$confirm("Atenção", msg);

        if (confirm) {
          this.updatingStatus = true;
          await this.vUpdateStatus({
            scheduleId: this.schedule.id,
            status: newStatus,
          });

          this.$emit("reload-schedules");
        }
      } catch (error) {
        this.handleError(error);
      } finally {
        this.updatingStatus = false;
      }
    },
    afterUpdatePatient() {
      this.personFormDialog = false;
      this.successDialog = true;
    },
    closeSuccessDialog() {
      this.successDialog = false;
      this.$emit("update-schedule");
    },
    handleError(error) {
      console.log(error);

      const errorHandled = errorHandler.treatError(error);

      this.$root.$errorDialog(errorHandled);
    },
  },
  created() {
    this.scheduleStatus = this.schedule.status;
  },
};
</script>

<style></style>
