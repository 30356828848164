import moneyHelper from "../helpers/money_helper";
import moment from "moment";

function discountAgreement(scheduling) {
  let discountValue = 0.0;
  let discountAgreement = scheduling.Agreement ? parseFloat(scheduling.Agreement.discount) : 0.0;

  discountValue = (scheduling.subtotal * discountAgreement) / 100;

  return discountValue.toFixed(2).replace(".", ",");
}

function discount(scheduling) {
  let discountValue = 0.0;

  if (scheduling.discountInPercentageFormat) {
    discountValue = (scheduling.subtotal * parseFloat(scheduling.discount)) / 100;
  } else {
    discountValue = parseFloat(scheduling.discount);
  }

  return discountValue.toFixed(2).replace(".", ",");
}

function calculateTotalPaid(paymentMethods) {
  let total = 0.0;

  for (const paymentMethod of paymentMethods) {
    if (paymentMethod.SchedulingPaymentMethod.status === "paid")
      total += parseFloat(paymentMethod.SchedulingPaymentMethod.value);
  }

  return total;
}

function printPaymentReceipt(payment) {
  let parsedPayment = { ...payment };

  // console.log(scheduling);

  // Open the print window
  const WinPrint = window.open(
    "",
    "",
    "left=0,top=0,width=400,height=900,toolbar=0,scrollbars=0,status=0"
  );

  let documentBody = "";

  let patientName =
    parsedPayment.Scheduling.Patient.Person.type === "physical-person"
      ? parsedPayment.Scheduling.Patient.Person.fullName
      : `${parsedPayment.Scheduling.Patient.Person.User.fantasyName}`;

  documentBody += `<!DOCTYPE html>
                        <html>
                          <head>
                          <title>Recibo - Agendamento: #${parsedPayment.SchedulingId} (${patientName})</title>
                          <style type="text/css">
                            body { font-size: 12px; font-family: Arial, Helvetica, sans-serif; }
                          </style>
                          </head>
                          <body>`;

  documentBody += `<p><b>${parsedPayment.Scheduling.CompanyBranch.fantasyName}</b></p>`;
  // documentBody += `<p>${parsedPayment.Scheduling.CompanyBranch.address}, ${parsedPayment.Scheduling.CompanyBranch.number} </p>`;

  documentBody += `<h2 style="text-align: center">RECIBO</h2>`;

  documentBody += `<p>PACIENTE: ${patientName}</p>`;
  documentBody += `<p>RESPONSÁVEL PELO PAGAMENTO: ${parsedPayment.nameOfResponsible}</p> <hr>`;

  documentBody += `<p> RECEBEMOS R$ ${moneyHelper.formatCurrencyValue(
    parseFloat(parsedPayment.value)
  )} EM: ${moment(parsedPayment.payDay).format("DD/MM/YYYY")} REFERENTE À: </p>`;

  for (const s of parsedPayment.Scheduling.Procedures) {
    documentBody += `+ ${s.name} (R$ ${
      s.SchedulingProcedure.price === null ? "" : s.SchedulingProcedure.price.replace(".", ",")
    })<br>`;
  }

  documentBody += `- Desconto = (R$ ${discount(parsedPayment.Scheduling)})<br>`;

  documentBody += `- Desconto pelo convênio = (R$ ${discountAgreement(
    parsedPayment.Scheduling
  )})<br>`;

  documentBody += "<br>";

  documentBody += `<p style="text-align: center">________________________________</p>`;
  documentBody += `<p style="text-align: center">Representante MultimagemRA</p>`;

  documentBody += `<p style="text-align: center"> IMPRESSO EM: ${moment().format(
    "DD/MM/YYYY [ás] HH:mm"
  )} </p>`;

  documentBody += `</body></html>`;

  WinPrint.document.write(documentBody);

  WinPrint.document.close();
  WinPrint.focus();
  WinPrint.print();
  //WinPrint.close();
}

function printSchedulePaymentReceipt(schedule) {
  const parsedSchedule = { ...schedule };

  const { Patient, CompanyBranch, PaymentMethods, Procedures, id } = parsedSchedule;

  // console.log(scheduling);

  // Open the print window
  const WinPrint = window.open(
    "",
    "",
    "left=0,top=0,width=400,height=900,toolbar=0,scrollbars=0,status=0"
  );

  let documentBody = "";

  let patientName =
    Patient.Person.type === "physical-person"
      ? Patient.Person.fullName
      : `${Patient.Person.fantasyName}`;

  documentBody += `<!DOCTYPE html>
                        <html>
                          <head>
                          <title>Recibo - Agendamento: #${id} (${patientName})</title>
                          <style type="text/css">
                            body { font-size: 12px; font-family: Arial, Helvetica, sans-serif; }
                          </style>
                          </head>
                          <body>`;

  documentBody += `<p><b>${CompanyBranch.fantasyName}</b></p>`;
  // documentBody += `<p>${CompanyBranch.address}, ${CompanyBranch.number} </p>`;

  documentBody += `<h2 style="text-align: center">RECIBO</h2>`;

  documentBody += `<p>PACIENTE: ${patientName}</p>`;

  documentBody += `<p> RECEBEMOS o valor total de ${moneyHelper.formatCurrencyValue(
    calculateTotalPaid(PaymentMethods)
  )}, SENDO QUE deste total `;

  for (const paymentMethod of PaymentMethods) {
    if (paymentMethod.SchedulingPaymentMethod.status === "paid")
      documentBody += ` ${moneyHelper.formatCurrencyValue(
        parseFloat(paymentMethod.SchedulingPaymentMethod.value)
      )} foi pago em ${paymentMethod.name} no dia ${moment(
        paymentMethod.SchedulingPaymentMethod.payDay
      ).format("DD/MM/YYYY")};`;
  }

  documentBody += ".</p> <p> REFERENTE À: </br></br>  ";

  for (const s of Procedures) {
    documentBody += `+ ${s.name} (R$ ${
      s.SchedulingProcedure.price === null ? "" : s.SchedulingProcedure.price.replace(".", ",")
    })<br>`;
  }

  documentBody += "</p>";

  documentBody += `<p>- Desconto = (R$ ${discount(schedule)})</p>`;

  documentBody += `<p>- Desconto pelo convênio = (R$ ${discountAgreement(schedule)})</p>`;

  documentBody += "<br>";

  documentBody += `<p style="text-align: center">________________________________</p>`;
  documentBody += `<p style="text-align: center">Representante MultimagemRA</p>`;

  documentBody += `<p style="text-align: center"> IMPRESSO EM: ${moment().format(
    "DD/MM/YYYY [ás] HH:mm"
  )} </p>`;

  documentBody += `</body></html>`;

  WinPrint.document.write(documentBody);

  WinPrint.document.close();
  WinPrint.focus();
  WinPrint.print();
  //WinPrint.close();
}

export { printPaymentReceipt, printSchedulePaymentReceipt };
