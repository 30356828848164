<template>
  <div class="info-tile">
    <div class="label body-2">
      <slot name="label"> {{ label }}</slot>
    </div>
    <div class="text subtitle-2">
      <slot name="text"> {{ text }}</slot>
    </div>
  </div>
</template>

<script>
export default {
  props: ["label", "text"],
};
</script>

<style scoped>
.v-text-field {
  padding-top: 0px;
}
</style>