<template>
  <div class="schedules-form">
    <ModalProgressIndicator v-if="loading && dialog" />

    <BaseForm
      v-else
      :title="internalTitle"
      :mod="mod"
      :flat="flat"
      :divider="divider"
      :dialog="dialog"
      :loading="loading"
      @save="save"
      @close-dialog="$emit('close-dialog')"
    >
      <template #header-actions>
        <v-menu
          bottom
          origin="center center"
          transition="scale-transition"
          v-if="computedMode === 'view' && form.status !== 'canceled'"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>

          <v-list>
            <v-list-item
              v-if="checkPermission('update-schedules')"
              @click="setMode('update')"
            >
              <v-list-item-icon>
                <v-icon>mdi-pencil</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Editar </v-list-item-title>
            </v-list-item>

            <v-list-item
              @click="cancelSchedule"
              v-if="
                form.status !== 'completed' &&
                  checkPermission('update-schedules-1')
              "
            >
              <v-list-item-icon>
                <v-icon>mdi-close</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Cancelar </v-list-item-title>
            </v-list-item>
            <v-list-item
              v-if="checkPermission('insert-schedules-1')"
              @click="addPayment"
            >
              <v-list-item-icon>
                <v-icon>mdi-cash-plus</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Incluir pagamento</v-list-item-title>
            </v-list-item>

            <v-list-item
              @click="printReceipt"
              v-if="scheduleData.PaymentMethods.length"
            >
              <v-list-item-icon>
                <v-icon>mdi-printer</v-icon>
              </v-list-item-icon>
              <v-list-item-title
                >Imprimir recibo de pagamentos</v-list-item-title
              >
            </v-list-item>
          </v-list>
        </v-menu>
      </template>

      <template #content>
        <ScheduleDetails
          @edit-payment="editPayment"
          @delete-payment="deletePayment"
          @reload-schedules="reloadSchedules"
          @update-schedule="loadInitialData"
          v-if="computedMode === 'view'"
          :schedule="form"
        />

        <v-container v-else>
          <v-row>
            <v-col>
              <v-select
                :loading="loadingCompanyBranches"
                label="Filial/Matriz:"
                v-model="formCompanyBranch"
                hide-details
                class="required"
                :items="filteredCompanyBranches"
                item-value="id"
                item-text="fantasyName"
              ></v-select
            ></v-col>
          </v-row>

          <v-row>
            <v-col>
              <ProceduresAutocomplete
                :companyBranch="formCompanyBranch"
                :multiple="true"
                v-model="formProcedures"
                :showPrices="true"
                :disabled="!formCompanyBranch"
                :chips="true"
              />
            </v-col>
          </v-row>

          <v-row class="mt-3">
            <v-col>
              <PeopleAutocomplete
                label="Paciente: "
                v-model="formPatient"
                :typeOfLink="3"
              />
            </v-col>
          </v-row>

          <v-row class="mt-3">
            <v-col>
              <v-text-field
                label="Data:"
                type="date"
                v-model="formDate"
                class="required"
              >
              </v-text-field>
            </v-col>

            <v-col>
              <v-text-field
                label="Horário de inicio:"
                type="time"
                v-model="formStartTime"
                class="required"
              >
              </v-text-field>
            </v-col>

            <v-col>
              <v-text-field
                label="Horário de término:"
                v-model="formEndTime"
                type="time"
                hide-details
                class="required"
              >
              </v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-radio-group
                label="Tipo de desconto"
                v-model="formDiscountType"
                row
              >
                <v-radio label="Porcentagem" :value="true"></v-radio>
                <v-radio label="Absoluto" :value="false"></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-text-field
                label="Desconto"
                type="number"
                v-model="formDiscount"
                :prefix="formDiscountType === true ? '%' : 'R$'"
                :max="formDiscountType === true ? 100 : parseFloat(subtotal)"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-select
                :loading="loadingAgreements"
                label="Convênio:"
                v-model="formAgreement"
                hide-details
                class="required"
                :items="agreements"
                item-value="id"
                item-text="name"
                :disabled="!formCompanyBranch"
              ></v-select
            ></v-col>
          </v-row>

          <v-row>
            <v-col class="d-flex justify-start">
              <div class="my-4">
                <div class="subtitle-1">
                  + Subtotal: {{ formatMoney(subtotal) }}
                </div>
                <div class="subtitle-1">
                  - Desconto por convênio: {{ formatMoney(discountAgreement) }}
                </div>
                <div class="subtitle-1">
                  - Desconto: {{ formatMoney(discount) }}
                </div>
                <div class="text-h6">+= Total: {{ formatMoney(total) }}</div>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </template>

      <template #actions>
        <v-spacer></v-spacer>
        <v-btn text v-if="dialog" @click.stop="$emit('close-dialog')">
          Fechar
        </v-btn>

        <v-btn
          v-if="
            !dialog && (computedMode === 'insert' || computedMode === 'update')
          "
          class="indigo white--text"
          @click="save('back')"
        >
          <v-icon left>mdi-keyboard-backspace</v-icon> Salvar e voltar</v-btn
        >
        <v-btn
          v-if="computedMode === 'insert' || computedMode === 'update'"
          class="success darken-1"
          @click="save('stay')"
        >
          <v-icon left>mdi-content-save</v-icon> Salvar</v-btn
        >
      </template>
    </BaseForm>

    <v-dialog v-model="successDialog" persistent max-width="450">
      <v-card>
        <v-card-text>
          <div class="text-center pa-4">
            <v-icon large color="success">mdi-check</v-icon>
          </div>

          <div class="text-center pa-3">
            <h3>Dados atualizados com sucesso</h3>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="closeSuccessDialog">Fechar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="paymentDialog" persistent max-width="600">
      <PaymentForm
        :externalLoading="loadingSchedulePaymentData"
        dialog
        @close-dialog="paymentDialog = false"
        @insert-completed="schedulePaymentHasInserted"
        @update-completed="schedulePaymentHasInserted"
      />
    </v-dialog>
  </div>
</template>

<script>
import formMixin from "@/mixins/formMixin";
import BaseForm from "@/components/template/BaseForm";
import errorHandler from "@/helpers/error_handler";
import { mask } from "vue-the-mask";
import ProceduresAutocomplete from "@/components/template/FormElements/Procedures/ProceduresAutocomplete.vue";
import PeopleAutocomplete from "@/components/template/FormElements/People/PeopleAutocomplete.vue";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import ModalProgressIndicator from "@/components/template/ModalProgressIndicator.vue";
import moment from "moment";
import ScheduleDetails from "@/components/Schedules/ScheduleDetails.vue";
import PaymentForm from "@/views/Schedules/Payment/PaymentForm.vue";
import { printSchedulePaymentReceipt } from "@/helpers/schedules";

export default {
  directives: { mask },
  components: {
    BaseForm,
    ProceduresAutocomplete,
    PeopleAutocomplete,
    ModalProgressIndicator,
    ScheduleDetails,
    PaymentForm,
  },
  mixins: [formMixin],
  props: ["externalLoading"],
  data: () => ({
    paymentDialog: false,
    internalLoading: false,
    loadingCompanyBranches: false,
    loadingAgreements: false,
    loadingSchedulePaymentData: false,
    successDialog: false,
    actionAfterOperation: null,
    scheduleInsertedOrUpdated: null,
    internalMod: null,
  }),
  watch: {
    computedMode(newMode, oldMode) {
      if (!(oldMode === "view" && newMode === "update")) this.clearForm();
    },
    formProcedures() {
      // console.log("Detectou mudança em procedimentos");
      this.updateEndTime();
    },
    formCompanyBranch(newValue) {
      if (newValue) this.loadAgreements();
    },
    formStartTime() {
      // console.log("Detectou mudança no horário de ínicio");
      this.updateEndTime();
    },
  },
  computed: {
    ...mapState("schedule", [
      "form",
      "scheduleData",
      "agreements",
      "companyBranches",
      "mode",
    ]),
    ...mapState("user", ["userData"]),
    ...mapGetters("schedule", ["getFormData"]),
    computedMode() {
      return this.mode || this.mod;
    },
    filteredCompanyBranches() {
      const { companyBranches, userHasBranchRestriction } = this.userData;

      return userHasBranchRestriction
        ? this.companyBranches.filter((b) => companyBranches.includes(b.id))
        : this.companyBranches;
    },
    subtotal() {
      let subtotal = 0.0;

      if (this.formProcedures && this.formProcedures.length)
        for (const procedure of this.formProcedures)
          subtotal += parseFloat(
            procedure.ProcedureCpBranches[0].BranchProcedurePricings[0].price
          );

      return subtotal;
    },
    discountAgreement() {
      let discountValue = 0.0;
      let agreement = this.agreements.find((a) => a.id === this.formAgreement);
      let discountAgreement = agreement ? parseFloat(agreement.discount) : 0.0;

      discountValue = (this.subtotal * discountAgreement) / 100;

      return discountValue;
    },
    discount() {
      let discountValue = 0.0;

      if (this.formDiscountType) {
        discountValue = (this.subtotal * parseFloat(this.formDiscount)) / 100;
      } else {
        discountValue = parseFloat(this.formDiscount);
      }

      return discountValue;
    },

    total() {
      let total = 0.0;

      total += this.subtotal;

      total -= this.discount;

      total -= this.discountAgreement;

      return total;
    },
    internalTitle() {
      return this.computedMode === "insert"
        ? "Novo agendamento"
        : `Detalhes do agendamento N. ${this.scheduleId}`;
    },
    loading() {
      return this.externalLoading || this.internalLoading;
    },
    scheduleId() {
      return this.form.id || this.databaseId || this.$route.params.id;
    },
    formStartTime: {
      get: function() {
        return this.getFormData("startTime");
      },
      set: function(value) {
        this.setFormData({ key: "startTime", value: value });
      },
    },
    formEndTime: {
      get: function() {
        return this.getFormData("endTime");
      },
      set: function(value) {
        this.setFormData({ key: "endTime", value: value });
      },
    },
    formDate: {
      get: function() {
        return this.getFormData("startDate");
      },
      set: function(value) {
        this.setFormData({ key: "startDate", value: value });
      },
    },
    formPatient: {
      get: function() {
        return this.getFormData("patient");
      },
      set: function(value) {
        this.setFormData({ key: "patient", value: value });
      },
    },
    formProcedures: {
      get: function() {
        return this.getFormData("procedures");
      },
      set: function(value) {
        this.setFormData({ key: "procedures", value: value });
      },
    },
    formAgreement: {
      get: function() {
        return this.getFormData("AgreementId");
      },
      set: function(value) {
        this.setFormData({ key: "AgreementId", value: value });
      },
    },
    formCompanyBranch: {
      get: function() {
        return this.getFormData("CompanyBranchId");
      },
      set: function(value) {
        this.setFormData({ key: "CompanyBranchId", value: value });
      },
    },
    formDiscountType: {
      get: function() {
        return this.getFormData("discountInPercentageFormat");
      },
      set: function(value) {
        this.setFormData({ key: "discountInPercentageFormat", value: value });
      },
    },
    formDiscount: {
      get: function() {
        return this.getFormData("discount");
      },
      set: function(value) {
        this.setFormData({ key: "discount", value: value });
      },
    },
  },
  methods: {
    ...mapActions("schedule", [
      "vLoadScheduleData",
      "vInitForm",
      "vLoadAgreements",
      "vLoadCompanyBranches",
      "vInsert",
      "vUpdate",
      "vUpdateStatus",
    ]),
    ...mapActions("schedulePayment", [
      "vLoadSchedulePaymentData",
      "vLoadPaymentScheduleData",
      "vDeleteSchedulePaymentData",
    ]),
    ...mapMutations("schedule", [
      "setFormData",
      "setDuration",
      "initForm",
      "clearForm",
      "setMode",
    ]),
    ...mapMutations(["schedulePayment/setMode"]),
    formatMoney(money) {
      return parseFloat(money).toLocaleString("pt-br", {
        style: "currency",
        currency: "BRL",
      });
    },
    validate() {
      const errors = [];

      if (!this.formProcedures || !this.formProcedures.length)
        errors.push("Informe pelo menos um PROCEDIMENTO");

      if (!this.formPatient)
        errors.push("Informe o PACIENTE relacionado a este agendamento");

      if (!this.formDate) errors.push("Informe uma DATA para o agendamento");

      if (!this.formStartTime)
        errors.push("Informe um HORÁRIO para o agendamento");

      if (!this.formAgreement)
        errors.push("Informe um convênio para o agendamento.");

      if (!this.formCompanyBranch)
        errors.push(
          "Informe o LOCAL/FILIAL de atendimento para o agendamento."
        );

      return errors;
    },
    save(actionAfterOperation) {
      const errors = this.validate();

      this.actionAfterOperation = actionAfterOperation;

      if (errors.length) {
        this.$root.$errorDialog(errors);
      } else {
        this[this.computedMode]();
      }
    },
    async insert() {
      try {
        this.internalLoading = true;

        this.scheduleInsertedOrUpdated = await this.vInsert();

        this.clearForm();

        this.successDialog = true;
      } catch (error) {
        this.handleError(error);
      } finally {
        this.internalLoading = false;
      }
    },
    async update() {
      try {
        this.internalLoading = true;

        this.scheduleInsertedOrUpdated = await this.vUpdate(this.scheduleId);

        this.successDialog = true;
      } catch (error) {
        this.handleError(error);
      } finally {
        this.internalLoading = false;
      }
    },
    async cancelSchedule() {
      try {
        const confirm = await this.$root.$confirm(
          "Atenção",
          "Deseja realmente CANCELAR o STATUS do agendamento?"
        );

        if (confirm) {
          this.internalLoading = true;
          this.successDialog = true;
          await this.vUpdateStatus({
            scheduleId: this.scheduleId,
            status: "canceled",
          });
        }
      } catch (error) {
        this.handleError(error);
      } finally {
        this.internalLoading = false;
      }
    },
    async loadInitialData() {
      try {
        this.internalLoading = true;

        await this.vLoadScheduleData(this.scheduleId);
      } catch (error) {
        this.handleError(error);
      } finally {
        this.internalLoading = false;
      }
    },
    async loadAgreements() {
      try {
        this.loadingAgreements = true;

        const params = {
          companyBranch: this.formCompanyBranch,
        };

        await this.vLoadAgreements({ params });
      } catch (error) {
        this.handleError(error);
      } finally {
        this.loadingAgreements = false;
      }
    },
    async loadCompanyBranches() {
      try {
        this.loadingCompanyBranches = true;

        await this.vLoadCompanyBranches();
      } catch (error) {
        this.handleError(error);
      } finally {
        this.loadingCompanyBranches = false;
      }
    },

    async deletePayment(paymentId) {
      try {
        this.internalLoading = true;

        await this.vDeleteSchedulePaymentData({
          paymentId,
          scheduleId: this.scheduleId,
        });

        await this.vLoadScheduleData(this.scheduleId);

        // await new Promise((r) => setTimeout(r, 2000));
      } catch (error) {
        this.handleError(error);
      } finally {
        this.internalLoading = false;
      }
    },
    reloadSchedules() {
      this.$emit("reload-schedules");
    },
    async addPayment() {
      try {
        this.loadingSchedulePaymentData = true;
        this["schedulePayment/setMode"]("insert");
        this.paymentDialog = true;

        await this.vLoadPaymentScheduleData(this.scheduleId);

        // await new Promise((r) => setTimeout(r, 2000));
      } catch (error) {
        this.handleError(error);
      } finally {
        this.loadingSchedulePaymentData = false;
      }
    },
    async editPayment(paymentId) {
      try {
        this.loadingSchedulePaymentData = true;
        this["schedulePayment/setMode"]("update");
        this.paymentDialog = true;

        await this.vLoadSchedulePaymentData({
          paymentId,
          scheduleId: this.scheduleId,
        });

        await new Promise((r) => setTimeout(r, 2000));
      } catch (error) {
        this.handleError(error);
      } finally {
        this.loadingSchedulePaymentData = false;
      }
    },
    async schedulePaymentHasInserted() {
      this.paymentDialog = false;

      await this.loadInitialData();
    },
    closeSuccessDialog() {
      this.successDialog = false;
      if (this.computedMode === "insert") {
        this.afterInsertion(this.actionAfterOperation, {
          ...this.scheduleInsertedOrUpdated,
        });
      } else if (this.computedMode === "update") {
        this.afterUpdate(this.actionAfterOperation);
      } else {
        this.$emit("close-dialog");
      }
    },
    checkPermission(permissionKey) {
      return (
        this.userData.accessProfile.Permissions.findIndex(
          (p) => p.key === permissionKey
        ) !== -1
      );
    },
    handleError(error) {
      console.log(error);

      const errorHandled = errorHandler.treatError(error);

      this.$root.$errorDialog(errorHandled);
    },
    updateEndTime() {
      let minutes = 0;

      for (const p of this.formProcedures) {
        if (p.durationTimeUnit === "hours") minutes += p.duration * 60;
        else if (p.durationTimeUnit === "minutes") minutes += p.duration;
      }

      const endTime = moment(
        `${this.formDate} ${this.formStartTime}`,
        "YYYY-MM-DD HH:mm"
      ).add(minutes, "minutes");

      if (
        minutes > 0 &&
        this.formStartTime &&
        (this.computedMode === "insert" || this.computedMode === "update")
      )
        this.setFormData({ key: "endTime", value: endTime.format("HH:mm") });
    },
    printReceipt() {
      const parsedData = JSON.parse(JSON.stringify(this.scheduleData));

      printSchedulePaymentReceipt(parsedData);
    },
  },
  created() {
    this.loadCompanyBranches();
  },
};
</script>

<style scoped>
.schedules-form >>> .v-chip--disabled {
  opacity: 1;
}

.schedules-form >>> .theme--light.v-select .v-select__selection--disabled {
  color: rgba(0, 0, 0, 0.87);
}
</style>
